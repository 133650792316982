import React, { useEffect, useState } from "react";
import "./style.scss";

const MultitenancyLogoPicker = () => {
  const [logoUrl, setLogoUrl] = useState("");

  useEffect(() => {
    let hostname = window.location.hostname.toLowerCase();
    // for Redesign url call defauld api
    if (hostname === process.env.REACT_APP_DEMO_STAGE) {
      hostname = process.env.REACT_APP_INVESTINET_STAGE;
    }

    // remove after API domain changed
    if (hostname === process.env.REACT_APP_INVESTINET_PROD) {
      hostname = "waythru.investinet.com";
    }
    const appPort = process.env.REACT_APP_BACKEND_PORT;
    const appApi = process.env.REACT_APP_BACKEND_API;
    const appPrefix = process.env.REACT_APP_BACKEND_API_PREFIX;
    const appProtocol = process.env.REACT_APP_BACKEND_API_PROTOCOL;

    const svgLogoUrl = `${appProtocol}://${appPrefix}${hostname}${appPort}${appApi}/assets/logo.svg`;
    const pngLogoUrl = `${appProtocol}://${appPrefix}${hostname}${appPort}${appApi}/assets/logo.png`;

    const loadLogo = async () => {
      try {
        const response = await fetch(svgLogoUrl);
        if (response.ok) {
          setLogoUrl(svgLogoUrl);
        } else {
          setLogoUrl(pngLogoUrl);
        }
      } catch (error) {
        setLogoUrl(pngLogoUrl);
      }
    };

    loadLogo();
  }, []);

  if (!logoUrl) {
    return null;
  }

  return <img className="logo-img" src={logoUrl} alt="logo" />;
};

export default MultitenancyLogoPicker;
