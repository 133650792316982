import React from "react";

import "./style.scss";

import WaythruLogo from "components/ReceiptModal/assets/receipt_logo2.png";
import ConsuegraLogo from "scss/images/consuegra-logo.png";
import WayLogo from "scss/images/logo_W.svg";
import trombergLogo from "scss/images/TMP_Logo.png";
import nclsLogo from "scss/images/ncls-logo.png";
import tidewaterLogo from "scss/images/tidewater-logo.png";

import { useTranslation } from "react-i18next";

let hostname = window.location.hostname.toLowerCase();
// for Redesign url call defauld api
if (hostname === process.env.REACT_APP_DEMO_STAGE) {
  hostname = process.env.REACT_APP_INVESTINET_STAGE;
}

// remove after API domain changed
if (hostname === process.env.REACT_APP_INVESTINET_PROD) {
  hostname = "waythru.investinet.com";
}
export const multitenancyTerms = () => {
  const { t } = useTranslation();

  switch (hostname) {
    case process.env.REACT_APP_INVESTINET_STAGE:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://www.investinet.com/terms-and-conditions"
          rel="noopener noreferrer"
        >
          {t("Terms and Conditions")}
        </a>
      );
    case process.env.REACT_APP_INVESTINET_PROD:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://www.investinet.com/terms-and-conditions"
          rel="noopener noreferrer"
        >
          {t("Terms and Conditions")}
        </a>
      );
    case process.env.REACT_APP_CONSUEGRA_STAGE:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://www.consuegralaw.com/assets/ConsuegraLaw-TermsConditions.pdf"
          rel="noopener noreferrer"
        >
          {t("Terms and Conditions")}
        </a>
      );
    case process.env.REACT_APP_CONSUEGRA_PROD:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://www.consuegralaw.com/assets/ConsuegraLaw-TermsConditions.pdf"
          rel="noopener noreferrer"
        >
          {t("Terms and Conditions")}
        </a>
      );
    case process.env.REACT_APP_DEMO_STAGE:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://waythru.com/terms-and-conditions"
          rel="noopener noreferrer"
        >
          {t("Terms and Conditions")}
        </a>
      );
    case process.env.REACT_APP_DEMO_PROD:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://waythru.com/terms-and-conditions"
          rel="noopener noreferrer"
        >
          {t("Terms and Conditions")}
        </a>
      );
    case process.env.REACT_APP_TROMBERG_STAGE:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://www.tmppllc.com/terms_conditions"
          rel="noopener noreferrer"
        >
          {t("Terms and Conditions")}
        </a>
      );
    case process.env.REACT_APP_TROMBERG_PROD:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://www.tmppllc.com/terms_conditions"
          rel="noopener noreferrer"
        >
          {t("Terms and Conditions")}
        </a>
      );
    case process.env.REACT_APP_NCLS_STAGE:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://northcentrallegalsolutions.com/contact/privacy/"
          rel="noopener noreferrer"
        >
          {t("Terms and Conditions")}
        </a>
      );
    case process.env.REACT_APP_NCLS_PROD:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://northcentrallegalsolutions.com/contact/privacy/"
          rel="noopener noreferrer"
        >
          {t("Terms and Conditions")}
        </a>
      );
    case process.env.REACT_APP_TIDEWATER_STAGE:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://twcs.com/Privacy-Policy"
          rel="noopener noreferrer"
        >
          {t("Terms and Conditions")}
        </a>
      );
    case process.env.REACT_APP_TIDEWATER_PROD:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://twcs.com/Privacy-Policy"
          rel="noopener noreferrer"
        >
          {t("Terms and Conditions")}
        </a>
      );
  }
};

export const multitenancyPrivacy = () => {
  const { t } = useTranslation();

  switch (hostname) {
    case process.env.REACT_APP_INVESTINET_STAGE:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://www.investinet.com/privacy-policy"
          rel="noopener noreferrer"
        >
          {t("Privacy Policy")}
        </a>
      );
    case process.env.REACT_APP_INVESTINET_PROD:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://www.investinet.com/privacy-policy"
          rel="noopener noreferrer"
        >
          {t("Privacy Policy")}
        </a>
      );
    case process.env.REACT_APP_CONSUEGRA_STAGE:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://www.consuegralaw.com/assets/ConsuegraLaw-PrivacyPolicy.pdf"
          rel="noopener noreferrer"
        >
          {t("Privacy Policy")}
        </a>
      );
    case process.env.REACT_APP_CONSUEGRA_PROD:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://www.consuegralaw.com/assets/ConsuegraLaw-PrivacyPolicy.pdf"
          rel="noopener noreferrer"
        >
          {t("Privacy Policy")}
        </a>
      );
    case process.env.REACT_APP_DEMO_STAGE:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://waythru.com/privacy-policy"
          rel="noopener noreferrer"
        >
          {t("Privacy Policy")}
        </a>
      );
    case process.env.REACT_APP_DEMO_PROD:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://waythru.com/privacy-policy"
          rel="noopener noreferrer"
        >
          {t("Privacy Policy")}
        </a>
      );
    case process.env.REACT_APP_TROMBERG_STAGE:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://www.tmppllc.com/privacy_policy"
          rel="noopener noreferrer"
        >
          {t("Privacy Policy")}
        </a>
      );
    case process.env.REACT_APP_TROMBERG_PROD:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://www.tmppllc.com/privacy_policy"
          rel="noopener noreferrer"
        >
          {t("Privacy Policy")}
        </a>
      );
    case process.env.REACT_APP_NCLS_STAGE:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://northcentrallegalsolutions.com/contact/privacy/"
          rel="noopener noreferrer"
        >
          {t("Privacy Policy")}
        </a>
      );
    case process.env.REACT_APP_NCLS_PROD:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://northcentrallegalsolutions.com/contact/privacy/"
          rel="noopener noreferrer"
        >
          {t("Privacy Policy")}
        </a>
      );
    case process.env.REACT_APP_TIDEWATER_STAGE:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://twcs.com/Privacy-Policy"
          rel="noopener noreferrer"
        >
          {t("Privacy Policy")}{" "}
        </a>
      );
    case process.env.REACT_APP_TIDEWATER_PROD:
      return (
        <a
          target="_blank"
          className="heading heading-caption-bold"
          href="https://twcs.com/Privacy-Policy"
          rel="noopener noreferrer"
        >
          {t("Privacy Policy")}{" "}
        </a>
      );
  }
};

export const multitenancyEsign = () => {
  const { t } = useTranslation();
  let hostname = window.location.hostname.toLowerCase();
  // for Redesign url call defauld api
  if (hostname === process.env.REACT_APP_DEMO_STAGE) {
    hostname = process.env.REACT_APP_INVESTINET_STAGE;
  }

  // remove after API domain changed
  if (hostname === process.env.REACT_APP_INVESTINET_PROD) {
    hostname = "waythru.investinet.com";
  }
  const appPort = process.env.REACT_APP_BACKEND_PORT;
  const appApi = process.env.REACT_APP_BACKEND_API;
  const appPrefix = process.env.REACT_APP_BACKEND_API_PREFIX;
  const appProtocol = process.env.REACT_APP_BACKEND_API_PROTOCOL;

  const esignUrl = `${appProtocol}://${appPrefix}${hostname}${appPort}${appApi}/assets/esign.pdf`;

  return (
    <a href={esignUrl} rel="noopener noreferrer" target="_blank">
      {t("E-Sign Disclosure")}
    </a>
  );
};

export const multitenancyReceiptTemplateLogo = () => {
  switch (hostname) {
    case process.env.REACT_APP_INVESTINET_STAGE:
      return WaythruLogo;
    case process.env.REACT_APP_INVESTINET_PROD:
      return WaythruLogo;
    case process.env.REACT_APP_CONSUEGRA_STAGE:
      return ConsuegraLogo;
    case process.env.REACT_APP_CONSUEGRA_PROD:
      return ConsuegraLogo;
    case process.env.REACT_APP_DEMO_STAGE:
      return WayLogo;
    case process.env.REACT_APP_DEMO_PROD:
      return WayLogo;
    case process.env.REACT_APP_TROMBERG_STAGE:
      return trombergLogo;
    case process.env.REACT_APP_TROMBERG_PROD:
      return trombergLogo;
    case process.env.REACT_APP_NCLS_STAGE:
      return nclsLogo;
    case process.env.REACT_APP_NCLS_PROD:
      return nclsLogo;
    case process.env.REACT_APP_TIDEWATER_STAGE:
      return tidewaterLogo;
    case process.env.REACT_APP_TIDEWATER_PROD:
      return tidewaterLogo;
  }
};

export const multitenancyPhone = () => {
  switch (hostname) {
    case process.env.REACT_APP_INVESTINET_STAGE:
      return "(888) 852-0054";
    case process.env.REACT_APP_INVESTINET_PROD:
      return "(888) 852-0054";
    case process.env.REACT_APP_CONSUEGRA_STAGE:
      return "(866) 798-9110";
    case process.env.REACT_APP_CONSUEGRA_PROD:
      return "(866) 798-9110";
    case process.env.REACT_APP_DEMO_STAGE:
      return "(888) 852-0054";
    case process.env.REACT_APP_DEMO_PROD:
      return "(888) 852-0054";
    case process.env.REACT_APP_TROMBERG_STAGE:
      return "(800) 280-6205";
    case process.env.REACT_APP_TROMBERG_PROD:
      return "(800) 280-6205";
    case process.env.REACT_APP_NCLS_STAGE:
      return "(833) 682-6539";
    case process.env.REACT_APP_NCLS_PROD:
      return "(833) 682-6539";
    case process.env.REACT_APP_TIDEWATER_STAGE:
      return "PHONE_NUMBER";
    case process.env.REACT_APP_TIDEWATER_PROD:
      return "PHONE_NUMBER";
  }
};

export const multitenancyHours = () => {
  switch (hostname) {
    case process.env.REACT_APP_INVESTINET_STAGE:
      return "Monday through Thursday 8:00 AM to 8:00 PM EST or Friday 8:00 AM to 4:00 PM EST";
    case process.env.REACT_APP_INVESTINET_PROD:
      return "Monday through Thursday 8:00 AM to 8:00 PM EST or Friday 8:00 AM to 4:00 PM EST";
    case process.env.REACT_APP_CONSUEGRA_STAGE:
      return "Monday - Friday 9:00 AM - 5:00 PM";
    case process.env.REACT_APP_CONSUEGRA_PROD:
      return "Monday - Friday 9:00 AM - 5:00 PM";
    case process.env.REACT_APP_DEMO_STAGE:
      return "Monday through Thursday 8:00 AM to 8:00 PM EST or Friday 8:00 AM to 4:00 PM EST";
    case process.env.REACT_APP_DEMO_PROD:
      return "Monday through Thursday 8:00 AM to 8:00 PM EST or Friday 8:00 AM to 4:00 PM EST";
    case process.env.REACT_APP_TROMBERG_STAGE:
      return "Monday to Friday, Eastern Standard Time 9 am - 5 pm";
    case process.env.REACT_APP_TROMBERG_PROD:
      return "Monday to Friday, Eastern Standard Time 9 am - 5 pm";
    case process.env.REACT_APP_NCLS_STAGE:
      return "Monday to Friday, Central Standard Time 8 am - 6 pm";
    case process.env.REACT_APP_NCLS_PROD:
      return "Monday to Friday, Central Standard Time 8 am - 6 pm";
    case process.env.REACT_APP_TIDEWATER_STAGE:
      return "EST. Mon.-Thurs. 8am to 5pm, Fri. 8am to 5:30pm, Saturday 8am to 12noon";
    case process.env.REACT_APP_TIDEWATER_PROD:
      return "EST. Mon.-Thurs. 8am to 5pm, Fri. 8am to 5:30pm, Saturday 8am to 12noon";
  }
};

export const multitenancyTelUrl = () => {
  switch (hostname) {
    case process.env.REACT_APP_INVESTINET_STAGE:
      return "tel:1 (888) 852-0054";
    case process.env.REACT_APP_INVESTINET_PROD:
      return "tel:1 (888) 852-0054";
    case process.env.REACT_APP_CONSUEGRA_STAGE:
      return "tel:1 (866) 798-9110";
    case process.env.REACT_APP_CONSUEGRA_PROD:
      return "tel:1 (866) 798-9110";
    case process.env.REACT_APP_DEMO_STAGE:
      return "tel:1 (888) 852-0054";
    case process.env.REACT_APP_DEMO_PROD:
      return "tel:1 (888) 852-0054";
    case process.env.REACT_APP_TROMBERG_STAGE:
      return "tel:1 (800) 280-6205";
    case process.env.REACT_APP_TROMBERG_PROD:
      return "tel:1 (800) 280-6205";
    case process.env.REACT_APP_NCLS_STAGE:
      return "tel:1 (833) 682-6539";
    case process.env.REACT_APP_NCLS_PROD:
      return "tel:1 (833) 682-6539";
    case process.env.REACT_APP_TIDEWATER_STAGE:
      return "tel:1 PHONE_NUMBER";
    case process.env.REACT_APP_TIDEWATER_PROD:
      return "tel:1 PHONE_NUMBER";
  }
};

export const multitenancyCompanyName = () => {
  switch (hostname) {
    case process.env.REACT_APP_INVESTINET_STAGE:
      return "InvestiNet, LLC";
    case process.env.REACT_APP_INVESTINET_PROD:
      return "InvestiNet, LLC";
    case process.env.REACT_APP_CONSUEGRA_STAGE:
      return "Consuegra & Duffy, P.L.L.C.";
    case process.env.REACT_APP_CONSUEGRA_PROD:
      return "Consuegra & Duffy, P.L.L.C.";
    case process.env.REACT_APP_DEMO_STAGE:
      return "Waythru Innovations, LLC";
    case process.env.REACT_APP_DEMO_PROD:
      return "Waythru Innovations, LLC";
    case process.env.REACT_APP_TROMBERG_STAGE:
      return "Tromberg, Morris & Partners, PLLC";
    case process.env.REACT_APP_TROMBERG_PROD:
      return "Tromberg, Morris & Partners, PLLC";
    case process.env.REACT_APP_NCLS_STAGE:
      return "North Central Legal Solutions, P.C.";
    case process.env.REACT_APP_NCLS_PROD:
      return "North Central Legal Solutions, P.C.";
    case process.env.REACT_APP_TIDEWATER_STAGE:
      return "Tidewater Finance Company";
    case process.env.REACT_APP_TIDEWATER_PROD:
      return "Tidewater Finance Company";
  }
};

export const multitenancyCompanyShortName = () => {
  switch (hostname) {
    case process.env.REACT_APP_INVESTINET_STAGE:
      return "Investinet";
    case process.env.REACT_APP_INVESTINET_PROD:
      return "Investinet";
    case process.env.REACT_APP_CONSUEGRA_STAGE:
      return "Consuegra";
    case process.env.REACT_APP_CONSUEGRA_PROD:
      return "Consuegra";
    case process.env.REACT_APP_DEMO_STAGE:
      return "Waythru";
    case process.env.REACT_APP_DEMO_PROD:
      return "Waythru";
    case process.env.REACT_APP_TROMBERG_STAGE:
      return "TMP";
    case process.env.REACT_APP_TROMBERG_PROD:
      return "TMP";
    case process.env.REACT_APP_NCLS_STAGE:
      return "NCLS";
    case process.env.REACT_APP_NCLS_PROD:
      return "NCLS";
    case process.env.REACT_APP_TIDEWATER_STAGE:
      return "TWCS";
    case process.env.REACT_APP_TIDEWATER_PROD:
      return "TWCS";
  }
};
